import React, { useState } from 'react';
import { InputArea } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface ParagraphProps extends WithErrorMessage {
  refInput?(element: React.Component | null): void;
  field: FormViewField;
  onChange(value: string): void;
  onBlur(): void;
}

const Paragraph = ({
  invalidMessage,
  errorDescribedBy,
  refInput,
  field: { key, renderInfo },
  onChange,
  onBlur,
}: ParagraphProps) => {
  const [value, setValue] = useState<string>(
    renderInfo?.displayProperties?.defaultValue || ''
  );
  const maxLength = renderInfo?.validationProperties?.string?.maxLength;

  const inputId = `${key}-input`;

  return (
    <FormField
      forId={inputId}
      dataHook="paragraph-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
      charCount={maxLength ? maxLength - value.length : undefined}
    >
      <InputArea
        id={inputId}
        ref={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        ariaDescribedby={invalidMessage ? errorDescribedBy : undefined}
        status={invalidMessage ? 'error' : undefined}
        dataHook="paragraph-input-area"
        placeholder={
          renderInfo?.displayProperties?.paragraphSettings?.placeholder
        }
        value={value}
        onBlur={() => onBlur()}
        onChange={(e) => {
          const newValue = e.target.value;

          if (
            maxLength === undefined ||
            (maxLength && newValue.length <= maxLength)
          ) {
            setValue(e.target.value);
            onChange(e.target.value);
          }
        }}
        resizable
      />
    </FormField>
  );
};

export default withErrorMessage(Paragraph);
