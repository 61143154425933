import React from 'react';
import { Text, AddItem, FileUpload, TextButton, Box } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';
import {
  acceptableFilesTypes,
  facebookAcceptableFileTypes,
  FileType,
} from '@wix/editor-elements-corvid-utils';
import { mediaTypeToFileType } from './utils';
import * as browserUtils from '@wix/thunderbolt-commons/dist/deprecatedBrowserUtils';
import { withTranslation, WithTranslation } from 'react-i18next';
import Delete from 'wix-ui-icons-common/Delete';

export interface FileUploadProps extends WithErrorMessage, WithTranslation {
  refInput?(element: React.Component | null): void;
  field: FormViewField;
  onChange(value: FileUploadValue): void;
}

const FileUploadComponent = ({
  refInput,
  field: { key, renderInfo },
  onChange,
  t,
}: FileUploadProps) => {
  const inputId = `${key}-input`;
  const buttonText =
    renderInfo?.displayProperties?.attachmentSettings?.buttonText;
  const mediaType = renderInfo?.displayProperties?.attachmentSettings
    ?.mediaType as MediaType;
  const fileType: FileType = mediaType
    ? mediaTypeToFileType[mediaType]
    : 'Image';

  const [selectedFile, setFile] = React.useState<File>();
  const fileInputRef = React.useRef<FileUpload | null>(null);

  const enhancedAcceptableFilesTypes =
    browserUtils.isFacebookApp(window) || browserUtils.isInstagramApp(window)
      ? browserUtils.isIOS(window)
        ? facebookAcceptableFileTypes.iOS
        : facebookAcceptableFileTypes.other
      : acceptableFilesTypes;

  const supportedFormats = enhancedAcceptableFilesTypes[fileType];

  const renderSelectedFile = () =>
    selectedFile ? (
      <Box marginTop="SP2" align="center" verticalAlign="middle">
        <Text size="small" dataHook="selected-file-name">
          {selectedFile.name}
        </Text>
        <TextButton
          dataHook="delete-file-btn"
          suffixIcon={<Delete />}
          onClick={() => {
            // @ts-expect-error
            const current = fileInputRef?.current?.inputRef?.current;
            current && (current.value = '');
            setFile(undefined);
          }}
        />
      </Box>
    ) : null;

  return (
    <FormField
      forId={inputId}
      dataHook="file-upload-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
    >
      <div>
        <div style={{ height: '139px' }}>
          <FileUpload
            multiple={false}
            accept={supportedFormats || '*'}
            ref={(ref) => {
              if (refInput) {
                refInput(ref);
              }

              fileInputRef.current = ref;
            }}
            dataHook="file-upload-file-picker"
            onChange={(selectedFiles) => {
              if (selectedFiles.length > 0) {
                const file = selectedFiles[0];
                onChange({ file, mediaType });
                setFile(file);
              } else {
                setFile(undefined);
              }
            }}
            capture="none"
          >
            {({ openFileUploadDialog }) => (
              <AddItem
                size="small"
                dataHook="file-upload-add-button"
                subtitle={
                  renderInfo?.displayProperties?.attachmentSettings?.placeholder
                }
                onClick={openFileUploadDialog}
              >
                {buttonText}
              </AddItem>
            )}
          </FileUpload>
        </div>
        {renderSelectedFile()}
      </div>
    </FormField>
  );
};

export default withTranslation()(withErrorMessage(FileUploadComponent));
