import { Prediction } from '@wix/ambassador-wix-atlas-service-web/types';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { AddressInput, DropdownLayoutOption } from '@wix/design-system';
import { useFormViewerService } from '../../../../services/useFormViewerService';
import { toServerCompatibleAddressFormat } from '../../../utils';
import { WithErrorMessage, withErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface AddressAutoCompleteProps extends WithErrorMessage {
  field: FormViewField;
  onChange(value: Address): void;
  refInput?(element: React.Component | null): void;
  onBlur(value: string): void;
}

const AddressAutoComplete = ({
  invalidMessage,
  field: { renderInfo },
  onChange,
  refInput,
  onBlur,
}: AddressAutoCompleteProps) => {
  const [value, setValue] = useState('');
  const [options, setOptions] = useState<DropdownLayoutOption[]>([]);

  const formViewerService = useFormViewerService();

  const countryCodes =
    renderInfo?.displayProperties?.autoCompleteAddressSettings?.filter
      ?.countryCodes || [];
  const handleInputChange = async (query: string) => {
    setValue(query);
    onChange(query ? { formatted: query } : {});
  };

  const debouncedServiceCall = useCallback(
    _.debounce(async (query) => {
      const {
        predictions = [],
      } = await formViewerService.getAddressPredictions(query, countryCodes);
      setOptions(
        predictions.map(
          (p: Prediction): DropdownLayoutOption => ({
            id: p.searchId as string,
            value: p.description,
          })
        )
      );
    }, 500),
    []
  );

  useEffect(() => {
    return () => {
      debouncedServiceCall.cancel();
    };
  }, []);

  const handleOptionSelection = async (option: DropdownLayoutOption) => {
    let address: Partial<Address> = { formatted: option.value as string };
    try {
      const response = await formViewerService.getAddress(option.id as string);
      address = response.place?.address
        ? toServerCompatibleAddressFormat(response.place.address)
        : address;
    } finally {
      setValue(option.value as string);
      onChange(address);
      onBlur(address.formatted as string);
    }
  };

  const handleClear = () => {
    setValue('');
    setOptions([]);
    onChange({});
  };

  return (
    <FormField
      dataHook="address-auto-complete-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
    >
      <AddressInput
        dataHook="address-input"
        value={value}
        placeholder={renderInfo?.displayProperties?.placeholder}
        border="standard"
        size="large"
        options={options}
        status={invalidMessage ? 'error' : undefined}
        ref={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        onChange={(event) => {
          handleInputChange(event.target.value);
          debouncedServiceCall(event.target.value);
        }}
        onSelect={(option) => handleOptionSelection(option)}
        onClear={handleClear}
        onBlur={() => onBlur(value)}
      />
    </FormField>
  );
};

export default withErrorMessage(AddressAutoComplete);
