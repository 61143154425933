import { BaseLogger } from '@wix/fedops-logger';
import _ from 'lodash';
import React, { useEffect, useRef } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { useFormViewerService } from '../../services/useFormViewerService';
import PremiumLogo from '../PremiumLogo/PremiumLogo';
import ErrorState from '../ErrorState/ErrorState';
import Form from '../Form/Form';
import { isEmptyField } from '../utils';
import s from './App.scss';

interface AppProps extends WithTranslation {
  isMobile: boolean;
  isPremium: boolean;
  fedopsLogger: BaseLogger<string>;
  form: FormView;
  error: ServerError | false;
  baseURL: string;
}

export const DataContext = React.createContext<{ baseURL: string }>({
  baseURL: '',
});

const App = ({
  form,
  isPremium,
  isMobile,
  fedopsLogger,
  error,
  baseURL,
}: AppProps) => {
  const fedops = useRef(fedopsLogger);

  const formViewerService = useFormViewerService();

  useEffect(() => {
    fedops.current.appLoaded();
  });

  const onSubmit = async (submission: Submission) => {
    fedops.current.interactionStarted('submit');

    const fields = await Promise.all(
      _.map(submission, async (f, key) => {
        if (!isEmptyField(f.value)) {
          if (f.type === 'SIGNATURE') {
            const response = await formViewerService.uploadSignature(
              f.value as string,
            );

            return { key, value: response };
          } else if (f.type === 'ATTACHMENTS') {
            const { file, mediaType } = f.value as FileUploadValue;
            const response = await formViewerService.uploadFile({
              domainMediaType: mediaType,
              file,
              fieldKey: key,
            });
            return { key, value: response };
          } else {
            return { key, value: f.value };
          }
        }
      }),
    );

    const res = await formViewerService.submitForm(_.compact(fields));
    fedops.current.interactionEnded('submit');
    return res;
  };

  const renderContent = () => {
    if (error) {
      return (
        <div className={s.errorContainer}>
          <ErrorState error={error} isMobile={isMobile} />
        </div>
      );
    }

    return (
      <div className={`${s.formContainer}${isMobile ? ' mobile' : ''}`}>
        <Form
          form={form}
          isMobile={isMobile}
          onSubmit={(submission) => onSubmit(submission)}
        />
        {!isPremium ? <PremiumLogo /> : null}
      </div>
    );
  };

  return (
    <DataContext.Provider value={{ baseURL }}>
      <div className={s.root}>{renderContent()}</div>
    </DataContext.Provider>
  );
};

export default withTranslation()(App);
