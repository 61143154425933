import React from 'react';
import { Heading, Text } from '@wix/design-system';
import css from './HeadingField.scss';

export interface HeadingFieldProps {
  field: FormViewField;
}

const HeadingField = ({ field: { renderInfo } }: HeadingFieldProps) => {
  const headingText =
    renderInfo?.displayProperties?.headingSettings?.headingText;
  const subheadingText =
    renderInfo?.displayProperties?.headingSettings?.subheadingText;
  return (
    <>
      {headingText && (
        <div className={css.heading}>
          <Heading dataHook="heading-text" size="medium">
            {headingText}
          </Heading>
        </div>
      )}
      {subheadingText && (
        <div className={css.subheading}>
          <Text dataHook="subheading-text" size="medium">
            {subheadingText}
          </Text>
        </div>
      )}
    </>
  );
};

export default HeadingField;
