import { DisplayPropertiesFieldOption } from '@wix/ambassador-wix-form-builder-web/types';
import React, { useState } from 'react';
import { Checkbox, Text } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';
import css from './CheckboxGroup.scss';

export interface CheckboxGroupProps extends WithErrorMessage {
  field: FormViewField;
  onChange(selection: string[]): void;
}

const RadioGroupComponent = ({
  errorDescribedBy,
  invalidMessage,
  field: { key, renderInfo },
  onChange,
}: CheckboxGroupProps) => {
  const [selection, setValue] = useState(
    renderInfo?.displayProperties?.defaultValue
      ? renderInfo?.displayProperties?.defaultValue
      : []
  );

  const labelId = `${key}-label`;

  const onToggleCheckbox = (option: DisplayPropertiesFieldOption) => {
    const updatedSelection = selection.includes(option.value)
      ? selection.filter((s: string) => s !== option.value)
      : [...selection, option.value];

    setValue(updatedSelection);
    onChange(updatedSelection);
  };

  return (
    <FormField
      labelId={labelId}
      dataHook="checkbox-group-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
    >
      <div
        aria-describedby={invalidMessage ? errorDescribedBy : undefined}
        aria-labelledby={labelId}
      >
        {renderInfo?.displayProperties?.checkboxGroupSettings?.options?.map(
          (option, idx) => (
            <div
              className={css.checkbox}
              role="checkbox"
              aria-checked={selection.includes(option.value)}
              onKeyPress={(e) => {
                if (e.nativeEvent.code === 'Space') {
                  onToggleCheckbox(option);
                  e.preventDefault();
                }
              }}
            >
              <Checkbox
                vAlign="top"
                dataHook={`checkbox-group-input-${idx}`}
                selectionArea="always"
                checked={selection.includes(option.value)}
                onChange={() => onToggleCheckbox(option)}
              >
                <Text secondary>{option.label}</Text>
              </Checkbox>
            </div>
          )
        )}
      </div>
    </FormField>
  );
};

export default withErrorMessage(RadioGroupComponent);
