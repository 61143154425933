import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { HttpError } from '@wix/http-client';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import { create as createFedopsLogger } from '@wix/fedops-logger';
import App from './components/App';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Event, EventHint } from '@sentry/browser';
import { CustomError } from './errors';
import '@wix/design-system/styles.global.css';

const baseURL = window.__BASEURL__;
const locale = window.__LOCALE__;
const isMobile = window.__IS_MOBILE__;
const isPremium = window.__IS_PREMIUM__;
const form = window.__FORM__;
const errorObject = window.__ERROR__;

const fedopsLogger = createFedopsLogger('forms-viewer-standalone');

window.Sentry.onLoad(() => {
  window.Sentry.init({
    beforeSend(event: Event, hint: EventHint) {
      const error = hint.originalException as any;

      if (!event.extra) {
        event.extra = {};
      }

      if (error && error.isCustomError) {
        const customError = error as CustomError;

        if (customError.originalException.isWixHttpError) {
          const httpError = customError.originalException as HttpError;
          event.extra.request = httpError.request;
          event.extra.response = httpError.response;
        } else {
          event.extra.originalException = customError.originalException;
        }

        event.fingerprint = [error.message];
      }

      return event;
    },
  });
});

ReactDOM.render(
  <Suspense fallback={<div />}>
    <I18nextProvider i18n={i18n(locale)}>
      <Router>
        <Route path="/f/:shortFormId">
          <App
            baseURL={baseURL}
            isMobile={isMobile}
            isPremium={isPremium}
            form={form}
            fedopsLogger={fedopsLogger}
            error={errorObject}
          />
        </Route>
        <Route path="/:instanceId::formId">
          <App
            baseURL={baseURL}
            isMobile={isMobile}
            isPremium={isPremium}
            form={form}
            fedopsLogger={fedopsLogger}
            error={errorObject}
          />
        </Route>
      </Router>
    </I18nextProvider>
  </Suspense>,
  document.getElementById('root'),
);
