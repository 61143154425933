import React from 'react';
import { Text } from '@wix/design-system';
import css from './TextBlock.scss';
import parse from 'html-react-parser';

export interface TextBlockFieldProps {
  field: FormViewField;
}

const TextBlockField = ({ field: { renderInfo } }: TextBlockFieldProps) => {
  const text = renderInfo?.displayProperties?.textBlockSettings?.text;
  return text ? (
    <Text dataHook="text-block" className={css.text} listStyle="circle">
      {parse(text!.replace(/(\r\n|\n|\r)/gm, ''))}
    </Text>
  ) : null;
};

export default TextBlockField;
