import React from 'react';
import { Box, Text } from '@wix/design-system';

export interface WithErrorMessage {
  invalidMessage?: string;
  errorDescribedBy?: string;
}

export const withErrorMessage = <P extends object>(
  Component: React.ComponentType<P>
) => (props: P & WithErrorMessage & { field?: FormViewField }) => {
  const errorDescribedBy = `${props.field?.key}-error-message`;
  return (
    <>
      <Component {...props} errorDescribedBy={errorDescribedBy} />
      {props.invalidMessage ? (
        <Box marginTop="8px" dataHook="error-message-wrapper">
          <Text
            dataHook="error-message"
            skin="error"
            size="small"
            id={errorDescribedBy}
          >
            {props.invalidMessage}
          </Text>
        </Box>
      ) : null}
    </>
  );
};

export default withErrorMessage;
