import React, { useState } from 'react';
import { Input } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface ShortTextProps extends WithErrorMessage {
  refInput?(element: HTMLInputElement | null): void;
  field: FormViewField;
  onChange(value: string): void;
  onBlur(value: string): void;
  hideCharCount?: boolean;
}

const ShortText = ({
  refInput,
  field: { key, renderInfo },
  invalidMessage,
  errorDescribedBy,
  onChange,
  onBlur,
  hideCharCount,
}: ShortTextProps) => {
  const [value, setValue] = useState<string>(
    renderInfo?.displayProperties?.defaultValue || ''
  );

  const updatedPrefix = React.useRef<boolean>(false);

  const maxLength = renderInfo?.validationProperties?.string?.maxLength;
  const charCount = maxLength ? maxLength - value.length : undefined;
  const fieldType = renderInfo?.type;

  let placeholder: string | undefined;
  let inputType: string | undefined;

  switch (fieldType) {
    case 'EMAIL':
      inputType = 'email';
      placeholder = renderInfo?.displayProperties?.emailSettings?.placeholder;
      break;
    case 'PHONE':
      inputType = 'tel';
      placeholder = renderInfo?.displayProperties?.phoneSettings?.placeholder;
      break;
    case 'URL':
      inputType = 'url';
      placeholder = renderInfo?.displayProperties?.urlSettings?.placeholder;
      break;
    default:
      placeholder = renderInfo?.displayProperties?.textSettings?.placeholder;
      break;
  }

  const inputId = `${key}-input`;

  const onValueBlur = () => {
    let updatedValue = value;

    if (inputType === 'url') {
      if (value === '') {
        updatedPrefix.current = false;
      }

      if (
        value !== '' &&
        !updatedPrefix.current &&
        !['http', 'ftp'].find((p) => updatedValue.startsWith(p))
      ) {
        updatedPrefix.current = true;
        updatedValue = `https://${updatedValue}`;

        setValue(updatedValue);
      }

      onChange(updatedValue);
    }

    onBlur(updatedValue);
  };

  return (
    <FormField
      forId={inputId}
      dataHook="short-text-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
      charCount={!hideCharCount ? charCount : undefined}
    >
      <Input
        id={inputId}
        ariaDescribedby={invalidMessage ? errorDescribedBy : undefined}
        inputRef={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        dataHook="short-text-input"
        size="large"
        placeholder={placeholder}
        value={value}
        status={invalidMessage ? 'error' : undefined}
        onBlur={() => onValueBlur()}
        onChange={(e) => {
          const newValue = e.target.value;

          if (
            maxLength === undefined ||
            (maxLength && newValue.length <= maxLength)
          ) {
            setValue(newValue);
            onChange(newValue);
          }
        }}
        pattern={renderInfo?.validationProperties?.string?.pattern}
        type={inputType}
      />
    </FormField>
  );
};

export default withErrorMessage(ShortText);
