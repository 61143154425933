import React, { useEffect } from 'react';
import { Trans, WithTranslation, withTranslation } from 'react-i18next';
import { Box, Heading, Text, TextButton } from '@wix/design-system';
import css from './ErrorState.scss';
import { ReactComponent as ErrorDesktopIcon } from './Error.desktop.svg';
import { ReactComponent as ErrorMobileIcon } from './Error.mobile.svg';
import { FetchFormError } from '../../errors';

export interface ErrorProps extends WithTranslation {
  error: ServerError;
  isMobile: boolean;
}

const ErrorState = ({ t, error, isMobile }: ErrorProps) => {
  let title;
  let description;

  useEffect(() => {
    window.Sentry.captureException(new FetchFormError(error));
  }, [error]);

  switch (error?.statusCode) {
    case 404:
      title = t('form.failedToLoad.formNotFound.title');
      description = t('form.failedToLoad.formNotFound.description');
      break;
    default:
      title = t('form.failedToLoad.internalError.title');
      description = (
        <Trans
          i18nKey="form.failedToLoad.internalError.description"
          components={[
            <TextButton
              key="refresh"
              dataHook="refresh-btn"
              size="small"
              onClick={() => window.location.reload()}
            >
              refresh
            </TextButton>,
          ]}
        />
      );
  }

  return (
    <div className={css.container} data-hook="error-state">
      <Box align="center" className={css.icon}>
        {isMobile ? <ErrorMobileIcon /> : <ErrorDesktopIcon />}
      </Box>
      <Box>
        <Heading
          size={isMobile ? 'medium' : 'large'}
          className={css.title}
          dataHook="title"
        >
          {title}
        </Heading>
      </Box>
      <Box>
        <Text
          className={css.description}
          dataHook="description"
          secondary
          size="small"
        >
          {description}
        </Text>
      </Box>
    </div>
  );
};

export default withTranslation()(ErrorState);
