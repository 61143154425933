import React, { useState } from 'react';
import { DatePicker, Input, LanguageType } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';
import css from './Date.scss';

export interface DateProps extends WithErrorMessage {
  refInput?(element: React.Component | null): void;
  field: FormViewField;
  onChange(value: string | undefined): void;
  locale: LanguageType;
  isMobile: boolean;
}

interface DateInputProps extends WithErrorMessage {
  refInput?(element: React.Component | null): void;
  inputId: string;
  field: FormViewField;
  onChange(value: string | undefined): void;
}
interface MobileDateInputProps extends DateInputProps {}

const MobileDateInput = ({
  refInput,
  inputId,
  field: { renderInfo },
  errorDescribedBy,
  invalidMessage,
  onChange,
}: MobileDateInputProps) => {
  // const [value, setValue] = useState<string | undefined>(
  //   renderInfo?.displayProperties?.defaultValue
  // );

  return (
    <div className={css.mobileDateField}>
      <Input
        ref={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        dataHook="date-input"
        size="large"
        id={inputId}
        placeholder={renderInfo?.displayProperties?.placeholder}
        defaultValue={renderInfo?.displayProperties?.defaultValue}
        ariaDescribedby={invalidMessage ? errorDescribedBy : undefined}
        status={invalidMessage ? 'error' : undefined}
        // onChange={({ target }) => {
        //   const { value: newValue } = target;
        //   setValue(newValue);
        //   onChange(newValue);
        //   setTimeout(() => {
        //     target.defaultValue = '';
        //   }, 100);
        // }}
        onBlur={(e) => {
          const newValue = e.target.value;
          onChange(newValue);
        }}
        type="date"
      />
    </div>
  );
};

interface DesktopDateInputProps extends DateInputProps {
  locale: LanguageType;
}

const DesktopDateInput = ({
  refInput,
  inputId,
  field: { renderInfo },
  invalidMessage,
  onChange,
  locale,
}: DesktopDateInputProps) => {
  const [value, setValue] = useState<Date | undefined>(
    renderInfo?.displayProperties?.defaultValue
      ? new Date(renderInfo?.displayProperties?.defaultValue)
      : undefined
  );
  return (
    <div className={css.desktopDateField}>
      <DatePicker
        ref={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        inputProps={{ id: inputId }}
        dataHook="date-input"
        size="large"
        width="100%"
        placeholderText={renderInfo?.displayProperties?.placeholder}
        value={value}
        status={invalidMessage ? 'error' : undefined}
        onChange={(newValue: Date) => {
          setValue(newValue);
          onChange(newValue.toLocaleDateString('sv'));
        }}
        locale={locale}
        showYearDropdown
        showMonthDropdown
      />
    </div>
  );
};

const DateInput = ({
  refInput,
  field: { renderInfo, key },
  invalidMessage,
  onChange,
  locale,
  isMobile,
}: DateProps) => {
  const inputId = `${key}-input`;

  return (
    <FormField
      dataHook="date-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
      forId={inputId}
    >
      {isMobile ? (
        <MobileDateInput
          refInput={refInput}
          onChange={onChange}
          invalidMessage={invalidMessage}
          field={{ renderInfo, key }}
          inputId={inputId}
        />
      ) : (
        <DesktopDateInput
          refInput={refInput}
          onChange={onChange}
          invalidMessage={invalidMessage}
          field={{ renderInfo, key }}
          inputId={inputId}
          locale={locale}
        />
      )}
    </FormField>
  );
};

export default withErrorMessage(DateInput);
