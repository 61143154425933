import React, { useState } from 'react';
import { NumberInput as WSRNumberInput } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface NumberProps extends WithErrorMessage {
  refInput?(element: HTMLInputElement | null): void;
  field: FormViewField;
  onChange(value: number | null): void;
  onBlur(value: number | null): void;
}

const NumberInput = ({
  refInput,
  field: { renderInfo, key },
  errorDescribedBy,
  invalidMessage,
  onChange,
  onBlur,
}: NumberProps) => {
  const [value, setValue] = useState<number | null>(
    typeof renderInfo?.displayProperties?.defaultValue === 'number'
      ? renderInfo?.displayProperties?.defaultValue
      : null,
  );

  const max = renderInfo?.validationProperties?.number?.max;
  const min = renderInfo?.validationProperties?.number?.min;
  const precision = renderInfo?.validationProperties?.number?.precision || 0;
  const step = 1 / Math.pow(10, precision);

  const onNumberBlur = () => {
    const fixedValue = Number(Number(value).toFixed(precision));
    setValue(fixedValue);
    onBlur(fixedValue);
  };

  const onNumberChange = (newValue: number | null) => {
    setValue(newValue);
    onChange(newValue);
  };

  const inputId = `${key}-input`;

  return (
    <FormField
      dataHook="number-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
      forId={inputId}
    >
      <WSRNumberInput
        inputRef={(e) => {
          if (refInput) {
            refInput(e);
          }
        }}
        dataHook="number-input"
        id={inputId}
        size="large"
        placeholder={renderInfo?.displayProperties?.numberSettings?.placeholder}
        value={typeof value === 'number' ? value : undefined}
        ariaDescribedby={invalidMessage ? errorDescribedBy : undefined}
        status={invalidMessage ? 'error' : undefined}
        onBlur={() => onNumberBlur()}
        step={step}
        max={max}
        min={min}
        onChange={(_value) => onNumberChange(_value)}
        onInvalid={(_value) => onNumberChange(Number(_value))}
      />
    </FormField>
  );
};

export default withErrorMessage(NumberInput);
