export const isEmptyField = (fieldValue: any): boolean =>
  fieldValue === null ||
  fieldValue === undefined || // not selected at all
  fieldValue === '' || // string
  fieldValue === false || // boolean
  (Array.isArray(fieldValue) && fieldValue.length === 0) || // arrays
  (typeof fieldValue === 'object' && Object.keys(fieldValue).length === 0);

export const toStreetAddress = (streetAddress?: {
  name?: string;
  number?: string;
}) => {
  if (!streetAddress) {
    return undefined;
  }
  const { name, number } = streetAddress;

  return name ? (number ? `${name}, ${number}` : name) : undefined;
};

export const toServerCompatibleAddressFormat = (rawValue: {
  formattedAddress?: string;
  country?: string;
  streetAddress?: {
    name?: string;
    number?: string;
  };
  postalCode?: string;
  subdivision?: string;
  city?: string;
}): Address => ({
  formatted: rawValue.formattedAddress,
  street: toStreetAddress(rawValue.streetAddress),
  city: rawValue.city,
  region: rawValue.subdivision,
  country: rawValue.country,
  zip: rawValue.postalCode,
});
