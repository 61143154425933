import React, { useState } from 'react';
import { Checkbox } from '@wix/design-system';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface CheckboxProps extends WithErrorMessage {
  field: FormViewField;
  onChange(selected: boolean): void;
}

const CheckboxComponent = ({
  errorDescribedBy,
  invalidMessage,
  field: { key, renderInfo },
  onChange,
}: CheckboxProps) => {
  const [selected, setValue] = useState(
    renderInfo?.displayProperties?.defaultValue || false
  );

  const labelId = `${key}-label`;

  return (
    <FormField
      labelId={labelId}
      dataHook="checkbox-form-field"
      label={renderInfo?.displayProperties?.label}
      required={!!renderInfo?.validationProperties?.required}
      labelPlacement="right"
      stretchContent={false}
    >
      <div
        aria-labelledby={labelId}
        aria-describedby={invalidMessage ? errorDescribedBy : undefined}
        role="checkbox"
        aria-checked={selected}
        onKeyPress={(e) => {
          if (e.nativeEvent.code === 'Space') {
            const updatedValue = !selected;
            setValue(updatedValue);
            onChange(updatedValue);
            e.preventDefault();
          }
        }}
      >
        <Checkbox
          vAlign="top"
          dataHook="checkbox-input"
          checked={selected}
          hasError={!!invalidMessage}
          onChange={() => {
            setValue(!selected);
            onChange(!selected);
          }}
        />
      </div>
    </FormField>
  );
};

export default withErrorMessage(CheckboxComponent);
