import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as LogoAscendSvg } from './logo-ascend.svg';
import { ReactComponent as LogoWixSvg } from './logo-wix.svg';
import { Box, Text, TextButton } from '@wix/design-system';
import css from './PremiumLogo.scss';

const isAscendToPremiumEnabled = window.__IS_ASCEND_TO_PREMIUM_ENABLED__;

export interface PremiumLogoProps extends WithTranslation {}

const PremiumLogo = ({ t }: PremiumLogoProps) => {
  const href = isAscendToPremiumEnabled
    ? `https://manage.wix.com/store/plans`
    : 'https://www.wix.com/ascend/home';

  return (
    <div className={css.logo}>
      <Box>
        <Text light={true} secondary={true} size="small" dataHook="logo-prefix">
          {t('form.ascendLogo.text')}
        </Text>
      </Box>
      <Box marginLeft="6px">
        <TextButton as="a" href={href} target="_blank" dataHook="logo-link">
          <Box>
            {isAscendToPremiumEnabled ? <LogoWixSvg /> : <LogoAscendSvg />}
          </Box>
        </TextButton>
      </Box>
    </div>
  );
};

export default withTranslation()(PremiumLogo);
