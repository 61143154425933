import { AttachmentSettingsMediaType as DomainMediaType } from '@wix/ambassador-wix-form-builder-web/types';
import {
  FileType,
  fileTypeToMediaType,
  MediaManagerMediaType,
  UploadedFileData,
} from '@wix/editor-elements-corvid-utils';
import { TFunction } from 'i18next';

export const mediaTypeToFileType: {
  [key in Exclude<DomainMediaType, DomainMediaType.UNDEFINED>]: FileType;
} = {
  [DomainMediaType.IMAGE]: 'Image',
  [DomainMediaType.VIDEO]: 'Video',
  [DomainMediaType.AUDIO]: 'Audio',
  [DomainMediaType.DOCUMENT]: 'Document',
  [DomainMediaType.IMAGE_AND_VIDEO]: 'Gallery',
};

export const mediaTypeToMediaManagerType: (
  mediaType: MediaType,
  fileName: string,
) => MediaManagerMediaType = (mediaType, fileName) => {
  const fileType = mediaTypeToFileType[mediaType];
  return fileTypeToMediaType(fileType, fileName);
};

export const uploadFileToMediaManager = ({
  uploadUrl,
  uploadToken,
  mediaManagerMediaType,
  file,
}: {
  uploadUrl: string;
  uploadToken: string;
  mediaManagerMediaType: MediaManagerMediaType;
  file: File;
}) => {
  const formData = new FormData();
  formData.append('upload_token', uploadToken);
  formData.append('media_type', mediaManagerMediaType);
  formData.append('file', file, file.name);
  formData.append('parent_folder_id', 'visitor-uploads');

  return new Promise<UploadedFileData[]>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.onload = () => {
      if (xhr.status !== 200) {
        reject(xhr.response);
      } else {
        resolve(xhr.response);
      }
    };

    xhr.open('POST', uploadUrl);
    xhr.send(formData);
  });
};

export const getFileValidationMessage = (
  translationKey: string,
  args: string[],
  t: TFunction,
) => {
  const key = `upload-button.${translationKey}`;
  const extension = (args && args[0]) || '';
  const sizeLimit = (args && args[1]) || '';
  // prefix & suffix at santa langs are {} and at forms are {{}}
  return t(key)
    .replace('{extension}', extension)
    .replace('{sizeLimit}', sizeLimit);
};
