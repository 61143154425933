import {
  PredictResponse,
  GetPlaceResponse,
} from '@wix/ambassador-wix-atlas-service-web/types';
import { HttpClient } from '@wix/http-client';
import { useContext } from 'react';
import { useParams } from 'react-router';
import { DataContext } from '../components/App/App';
import {
  createMediaSrc,
  getFileDataByType,
  mediaTypeToMediaSrcType,
} from '@wix/editor-elements-corvid-utils';
import {
  mediaTypeToFileType,
  mediaTypeToMediaManagerType,
  uploadFileToMediaManager,
} from '../components/Form/Fields/FileUploadInput/utils';
import { FileUploadError } from '../errors';

interface AppProps {
  instanceId?: string;
  formId?: string;
  shortFormId?: string;
}

export interface FormViewerService {
  fetchForm(): Promise<{ form: FormView; isPremium: boolean }>;
  submitForm(fields: V2Field[]): Promise<FormView>;
  uploadSignature(signature: string): Promise<{ name: string; url: string }>;
  getAddressPredictions(
    input: string,
    countryCodes: string[],
  ): Promise<PredictResponse>;
  getAddress(searchId: string): Promise<GetPlaceResponse>;
  uploadFile({
    domainMediaType,
    file,
    fieldKey,
  }: {
    domainMediaType: MediaType;
    file: File;
    fieldKey: string;
  }): Promise<{ url: string; name: string }[]>;
}

export const useFormViewerService = (): FormViewerService => {
  const { baseURL } = useContext(DataContext);
  const { instanceId, formId, shortFormId } = useParams<AppProps>();
  const httpClient = new HttpClient({ baseURL: window?.location?.origin });

  const fetchForm = (): Promise<{ form: FormView; isPremium: boolean }> => {
    return httpClient
      .get(`${baseURL}_api/form/${instanceId}:${formId}`)
      .then((r) => r.data);
  };

  const submitForm = (fields: V2Field[]): Promise<FormView> => {
    const url = formId
      ? `${baseURL}_api/form/${instanceId}:${formId}/submit`
      : `${baseURL}_api/form/${shortFormId}/submit`;

    return httpClient
      .post(url, {
        fields,
      })
      .then((r) => r.data);
  };

  const uploadSignature = (
    signature: string,
  ): Promise<{ name: string; url: string }> => {
    const url = formId
      ? `${baseURL}_api/form/${instanceId}:${formId}/upload-signature`
      : `${baseURL}_api/form/${shortFormId}/upload-signature`;

    return httpClient
      .post(url, {
        signature,
      })
      .then((r) => r.data);
  };

  const getAddressPredictions = (
    input: string,
    countryCodes: string[] = [],
  ): Promise<PredictResponse> => {
    const url = formId
      ? `${baseURL}_api/form/${instanceId}:${formId}/autocomplete/predict`
      : `${baseURL}_api/form/${shortFormId}/autocomplete/predict`;

    return httpClient
      .post(url, {
        input,
        countryCodes,
      })
      .then((r) => r.data);
  };

  const getAddress = (searchId: string): Promise<GetPlaceResponse> => {
    const url = formId
      ? `${baseURL}_api/form/${instanceId}:${formId}/autocomplete/getAddress`
      : `${baseURL}_api/form/${shortFormId}/autocomplete/getAddress`;

    return httpClient
      .post(url, {
        searchId,
      })
      .then((r) => r.data);
  };

  const uploadFile: ({
    domainMediaType,
    file,
    fieldKey,
  }: {
    domainMediaType: MediaType;
    file: File;
    fieldKey: string;
  }) => Promise<{ url: string; name: string }[]> = async ({
    domainMediaType,
    file,
    fieldKey,
  }) => {
    const mediaManagerMediaType = mediaTypeToMediaManagerType(
      domainMediaType,
      file.name,
    );
    const url = formId
      ? `${baseURL}_api/form/${instanceId}:${formId}/get-upload-url?media_type=${mediaManagerMediaType}&file_name=${file.name}`
      : `${baseURL}_api/form/${shortFormId}/get-upload-url?media_type=${mediaManagerMediaType}&file_name=${file.name}`;

    const { uploadUrl, uploadToken } = await httpClient
      .get(url)
      .then(
        ({ data }: { data: { uploadUrl: string; uploadToken: string } }) =>
          data,
      )
      .catch((e) => {
        throw new FileUploadError(e, fieldKey);
      });

    const uploadFileToMediaResponse = await uploadFileToMediaManager({
      uploadToken,
      uploadUrl,
      mediaManagerMediaType,
      file,
    }).catch((e) => {
      throw new FileUploadError(e, fieldKey);
    });

    const [uploadFileData] = uploadFileToMediaResponse;

    const fileData = {
      uri: '',
      filename: '',
      posterUri: '',
      duration: '',
      ...getFileDataByType(
        mediaTypeToFileType[domainMediaType],
        uploadFileData,
      ),
    };

    const mediaSrc = createMediaSrc({
      type: mediaTypeToMediaSrcType[fileData.media_type],
      mediaId: fileData.uri,
      title: fileData.filename,
      height: fileData.height,
      width: fileData.width,
      posterId: fileData.posterUri,
      duration: fileData.duration,
    });

    if (mediaSrc.error || !mediaSrc.item) {
      const message = mediaSrc.error || 'No File Url Generated';
      throw new FileUploadError(
        { error_info: { key: 'error.general' }, error_description: message },
        fieldKey,
      );
    }

    return [
      {
        url: mediaSrc.item,
        name: uploadFileData.original_file_name,
      },
    ];
  };

  return {
    fetchForm,
    submitForm,
    uploadSignature,
    getAddressPredictions,
    getAddress,
    uploadFile,
  };
};
