import React, { useState } from 'react';
import { Dropdown } from '@wix/design-system';
import { WithErrorMessage, withErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface DropdownProps extends WithErrorMessage {
  refInput?(element: React.Component | null): void;
  field: FormViewField;
  onChange(value: string): void;
  isMobile: boolean;
}

const PLACEHOLDER = 'PLACEHOLDER';

const DropdownComponent = ({
  invalidMessage,
  errorDescribedBy,
  refInput,
  field: { key, renderInfo },
  onChange,
  isMobile,
}: DropdownProps) => {
  const [selectedId, setValue] = useState(
    renderInfo?.displayProperties?.defaultValue
  );

  const labelId = `${key}-label`;

  const options = renderInfo?.displayProperties?.dropdownSettings?.options?.map(
    (option, idx) => ({
      id: `${key}-option${idx}`,
      value: option.label, // Switched by design, native select takes option.value as both the value and the label
      label: option.value, // Using this when calling onChange to propagate value and not label
    })
  );

  if (!selectedId && options) {
    options.unshift({
      id: PLACEHOLDER,
      value: renderInfo?.displayProperties?.dropdownSettings?.placeholder,
      label: '',
    });
  }

  return (
    <div aria-labelledby={labelId}>
      <FormField
        labelId={labelId}
        dataHook="dropdown-form-field"
        label={renderInfo?.displayProperties?.label}
        required={!!renderInfo?.validationProperties?.required}
      >
        <Dropdown
          ref={(e) => {
            if (refInput) {
              refInput(e);
            }
          }}
          placeholder={
            renderInfo?.displayProperties?.dropdownSettings?.placeholder
          }
          selectedId={selectedId}
          onSelect={(selectedOption) => {
            setValue(selectedOption.id);

            if (selectedOption.id !== PLACEHOLDER) {
              onChange(selectedOption.label as string);
            }
          }}
          ariaDescribedby={invalidMessage ? errorDescribedBy : undefined}
          status={invalidMessage ? 'error' : undefined}
          size="large"
          dataHook="dropdown-input"
          options={options}
          native={isMobile}
        />
      </FormField>
    </div>
  );
};

export default withErrorMessage(DropdownComponent);
