import React, { useEffect } from 'react';
import { withErrorMessage, WithErrorMessage } from '../../withErrorMessage';
import {
  SignatureInput as CoreSignatureInput,
  SignaturePadApi,
} from 'wix-ui-core/dist/src/components/signature-input';
import { Box, Text, TextButton } from '@wix/design-system';
import css from './SignatureInput.scss';

export interface SignatureProps extends WithErrorMessage {
  field: FormViewField;
  onChange(value: string | undefined): void;
  refInput?({ focus, clear }: { focus(): void; clear(): void }): void;
}

const asterisk = (
  <div data-hook="asterisk" className={css.asterisk} children="*" />
);

const SignatureInput = ({
  field: { key, renderInfo },
  errorDescribedBy,
  invalidMessage,
  onChange,
  refInput,
}: SignatureProps) => {
  const padApi = React.useRef<SignaturePadApi>();
  const wrapperRef = React.useRef<HTMLDivElement>();
  const [hasPadFocus, setFocus] = React.useState<boolean>(false);

  const refInputRef = React.useRef(refInput);

  useEffect(() => {
    if (refInputRef.current) {
      refInputRef.current({
        focus: () => {
          setFocus(true);

          if (wrapperRef.current) {
            wrapperRef.current.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
            });
          }

          return padApi.current?.focus();
        },
        clear: () => {
          return padApi.current?.clear();
        },
      });
    }
  }, []);

  const focusStyles = hasPadFocus
    ? {
        outline: 'none',
        borderColor: '#3899EC',
        boxShadow: invalidMessage ? '0 0 0 3px #ffd7d7' : '0 0 0 3px #aadbfc',
      }
    : {};

  const errorStyles = invalidMessage
    ? {
        outline: 'none',
        borderColor: '#ee5951',
      }
    : {};

  const labelId = `${key}-label`;

  return (
    <div
      className={css.wrapper}
      ref={(ref) => {
        if (ref) {
          wrapperRef.current = ref;
        }
      }}
    >
      <CoreSignatureInput>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <CoreSignatureInput.Title>
            {({ getTitleProps }) => (
              <div className={css.formField}>
                <label
                  className={css.label}
                  id={labelId}
                  data-hook="signature-form-field"
                >
                  <Text secondary {...getTitleProps()}>
                    {renderInfo?.displayProperties?.label}
                  </Text>
                </label>
                {renderInfo?.validationProperties?.required ? asterisk : null}
              </div>
            )}
          </CoreSignatureInput.Title>
          <CoreSignatureInput.SigningPad
            data-hook="signature-input"
            aria-labelledby={labelId}
            aria-describedby={invalidMessage ? errorDescribedBy : undefined}
            css
            className={css.pad}
            style={{ ...focusStyles, ...errorStyles }}
            onInit={(api: SignaturePadApi) => {
              padApi.current = api;
            }}
            onFocus={() => setFocus(true)}
            onBlur={() => setFocus(false)}
            onDraw={() => {
              onChange(padApi.current?.toDataURL());
            }}
          />
          <CoreSignatureInput.ClearButton>
            {() => (
              <Box alignSelf="flex-end" marginTop="6px">
                <TextButton
                  size="small"
                  as="button"
                  onClick={(e) => {
                    e.preventDefault();
                    padApi.current && padApi.current.clear();
                    onChange(undefined);
                  }}
                >
                  {
                    renderInfo?.displayProperties?.signatureSettings
                      ?.clearButtonLabel
                  }
                </TextButton>
              </Box>
            )}
          </CoreSignatureInput.ClearButton>
        </div>
      </CoreSignatureInput>
    </div>
  );
};

export default withErrorMessage(SignatureInput);
