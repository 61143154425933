import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  LanguageType,
  Loader,
  Text,
} from '@wix/design-system';
import css from './Form.scss';
import AddressAutoComplete from './Fields/AddressAutoComplete/AddressAutoComplete';
import ShortText from './Fields/ShortText/ShortText';
import Paragraph from './Fields/Paragraph/Paragraph';
import RadioGroup from './Fields/RadioGroup/RadioGroup';
import CheckboxGroup from './Fields/CheckboxGroup/CheckboxGroup';
import Checkbox from './Fields/Checkbox/Checkbox';
import Dropdown from './Fields/Dropdown/Dropdown';
import SignatureInput from './Fields/SignatureInput/SignatureInput';
import FileUploadInput from './Fields/FileUploadInput/FileUploadInput';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ReactComponent as SuccessIcon } from './images/success-icon.svg';
import { reportBi } from '../../bi';
import { SubmitFormError } from '../../errors';
import NumberInput from './Fields/NumberInput/NumberInput';
import DateField from './Fields/Date/Date';
import { isEmptyField } from '../utils';
import { getFileValidationMessage } from './Fields/FileUploadInput/utils';
import HeadingField from './Fields/HeadingField/HeadingField';
import TextBlockField from './Fields/TextBlockField/TextBlockField';
import { SubscribeField } from './Fields/SubscribeField/SubscribeField';
import { FormFieldViewInfoFieldType } from '@wix/ambassador-wix-form-builder-web/types';
import {
  formBuilderBackofficeFormSubmissionClick,
  formBuilderBackofficeFormSubmissionSuccess,
  formBuilderBackofficeFormSubmissionFailed,
} from '@wix/bi-logger-forms-viewer-standalone/v2';

export interface FormProps extends WithTranslation {
  form: FormView;
  isMobile: boolean;
  onSubmit(submission: Submission): Promise<FormView>;
}

interface Error {
  [k: string]: string | undefined;
}

interface SubmissionState {
  submitted: boolean;
  submitting: boolean;
  errorCode: string | number | undefined;
}

const PRE_DEFINED_LIMIT = [
  'firstName',
  'lastName',
  'email',
  'phone',
  'address',
  'url',
];

const overridesClassPerFieldType: {
  [key in FormFieldViewInfoFieldType]?: string;
} = {
  [FormFieldViewInfoFieldType.HEADING]: css.heading,
};

const Form = ({ t, form, onSubmit, i18n, isMobile }: FormProps) => {
  const containerClassName = `${css.container} ${
    form?.formViewInfo?.direction === 'RTL' ? css.rtl : ''
  }`;
  const [submissionState, setSubmissionState] = useState<SubmissionState>({
    submitted: false,
    submitting: false,
    errorCode: undefined,
  });

  const [submission, updateSubmission] = React.useState<Submission>(
    form.fields?.reduce<Submission>((acc, f) => {
      acc[f.key as string] = {
        type: f.renderInfo?.type,
        value: f.renderInfo?.displayProperties?.defaultValue,
      };
      return acc;
    }, {}) || {}
  );

  const [errors, updateErrors] = React.useState<Error>({});

  const inputRefs = React.useRef<{
    [k: string]: (HTMLInputElement | React.Component | HTMLDivElement) & {
      focus?: Function;
      scrollIntoView?: Function;
      nodeName?: string;
    };
  }>({});

  const fieldRefs = React.useRef<{ [k: string]: HTMLDivElement }>({});

  const getBiEventData = () => ({
    form_comp_id: form.formId,
    form_url: window.location.href,
    form_name: form.formViewInfo?.title,
  });

  const onFieldChange = (field: FormViewField, value: any) => {
    updateSubmission((prev) => ({
      ...prev,
      [field.key as string]: {
        type: prev[field.key as string].type,
        value,
      },
    }));
  };

  const onFieldBlur = (field: FormViewField, updatedValue: SubmissionValue) => {
    const key = field.key as string;

    updateErrors((prev) => {
      if (prev[key]) {
        const { invalidMessage } = validateField(field, updatedValue);

        return {
          ...prev,
          [field.key as string]: invalidMessage,
        };
      }

      return prev;
    });
  };

  const renderField = (field: FormViewField) => {
    const key = field.key as string;

    switch (field.renderInfo?.type) {
      case 'TEXT':
      case 'PHONE':
      case 'URL':
      case 'EMAIL':
        return (
          <ShortText
            refInput={(element: HTMLInputElement) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onBlur={(updatedValue) => onFieldBlur(field, updatedValue)}
            onChange={(value) => onFieldChange(field, value)}
            invalidMessage={errors[key]}
            hideCharCount={!!PRE_DEFINED_LIMIT.find((id) => key.startsWith(id))}
          />
        );
      case 'PARAGRAPH':
        return (
          <Paragraph
            refInput={(element: React.Component) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onBlur={() => onFieldBlur(field, submission[key].value)}
            onChange={(value) => onFieldChange(field, value)}
            invalidMessage={errors[key]}
          />
        );
      case 'RADIO_GROUP':
        return (
          <RadioGroup
            field={field}
            onChange={(value) => {
              onFieldChange(field, value);
              onFieldBlur(field, value);
            }}
            invalidMessage={errors[key]}
          />
        );
      case 'CHECKBOX_GROUP':
        return (
          <CheckboxGroup
            field={field}
            onChange={(value) => {
              onFieldChange(field, value);
              onFieldBlur(field, value);
            }}
            invalidMessage={errors[key]}
          />
        );
      case 'CHECKBOX':
        return (
          <Checkbox
            field={field}
            onChange={(value) => {
              onFieldChange(field, value);
              onFieldBlur(field, value);
            }}
            invalidMessage={errors[key]}
          />
        );
      case 'DROP_DOWN':
        return (
          <Dropdown
            refInput={(element: React.Component) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onChange={(value) => {
              onFieldChange(field, value);
              onFieldBlur(field, value);
            }}
            invalidMessage={errors[key]}
            isMobile={true} // desktop behavior does not support a11y
          />
        );
      case 'NUMBER':
        return (
          <NumberInput
            refInput={(element: HTMLInputElement) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onBlur={(updatedValue) => onFieldBlur(field, updatedValue)}
            onChange={(value) => onFieldChange(field, value)}
            invalidMessage={errors[key]}
          />
        );
      case 'DATE_PICKER':
        return (
          <DateField
            refInput={(element: React.Component) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onChange={(value) => onFieldChange(field, value)}
            invalidMessage={errors[key]}
            locale={i18n.language as LanguageType}
            isMobile={isMobile}
          />
        );
      case 'SIGNATURE':
        return (
          <SignatureInput
            refInput={(element: any) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onChange={(value) => {
              onFieldChange(field, value);
              onFieldBlur(field, value);
            }}
            invalidMessage={errors[key]}
          />
        );
      case 'AUTO_COMPLETE_ADDRESS':
        return (
          <AddressAutoComplete
            refInput={(element: React.Component) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onChange={(value: Address) => onFieldChange(field, value)}
            onBlur={(updatedValue) => onFieldBlur(field, updatedValue)}
            invalidMessage={errors[key]}
          />
        );

      case 'ATTACHMENTS':
        return (
          <FileUploadInput
            refInput={(element: React.Component) => {
              inputRefs.current[key] = element;
            }}
            field={field}
            onChange={(value) => onFieldChange(field, value)}
            invalidMessage={errors[key]}
          />
        );
      case 'HEADING':
        return <HeadingField field={field} />;
      case 'TEXT_BLOCK':
        return <TextBlockField field={field} />;
      case 'SUBSCRIPTION_CHECKBOX':
        return (
          <SubscribeField
            field={field}
            onChange={(value) => onFieldChange(field, value)}
          />
        );
      default:
        return null;
    }
  };

  const validateNumberField = (field: FormViewField, value: string) => {
    const actualInput = inputRefs.current[
      field.key as string
    ] as HTMLInputElement;
    actualInput.checkValidity();
    const validationProperties = field.renderInfo?.validationProperties;
    if (
      typeof validationProperties?.number?.max === 'number' ||
      typeof validationProperties?.number?.min === 'number'
    ) {
      const min = validationProperties.number.min;
      const max = validationProperties.number.max;
      if ((min && Number(value) < min) || (max && Number(value) > max)) {
        return {
          invalidMessage: t('field.errors.number.minMaxValueReached', {
            min,
            max,
          }),
        };
      }
    }

    return {};
  };

  const validateField = (
    field: FormViewField,
    updatedValue: SubmissionValue
  ): { invalidMessage?: string } => {
    if (field.renderInfo?.validationProperties?.required) {
      if (isEmptyField(updatedValue)) {
        let invalidMessage;

        switch (field.renderInfo?.type) {
          case 'CHECKBOX':
            invalidMessage = t('field.errors.required.checkbox');
            break;
          case 'CHECKBOX_GROUP':
            invalidMessage = t('field.errors.required.checkboxGroup');
            break;
          case 'RADIO_GROUP':
            invalidMessage = t('field.errors.required.radio');
            break;
          default:
            invalidMessage = t('field.errors.required');
        }

        return { invalidMessage };
      }
    }

    const value = updatedValue as string;

    if (value) {
      const pattern = field.renderInfo?.validationProperties?.string?.pattern;

      if (pattern) {
        const actualInput = inputRefs.current[
          field.key as string
        ] as HTMLInputElement;
        actualInput.checkValidity();
        const isInvalidPattern =
          actualInput.validity.patternMismatch ||
          actualInput.validity.typeMismatch;

        if (isInvalidPattern) {
          let invalidMessage;

          switch (field.renderInfo?.type) {
            case 'EMAIL':
              invalidMessage = t('field.errors.format.email');
              break;
            case 'URL':
              invalidMessage = t('field.errors.format.URL');
              break;
            case 'PHONE':
              invalidMessage = t('field.errors.format.phone');
              break;
            default:
              invalidMessage = t('field.errors.format');
          }

          return { invalidMessage };
        }
      }
    }

    if (field.renderInfo?.type === 'NUMBER') {
      return validateNumberField(field, value);
    }

    return {};
  };

  const validateSubmission = (): Error => {
    let focused = false;

    const currentErrors =
      form.fields?.reduce<Error>((acc, curr) => {
        const key = curr.key as string;

        const { invalidMessage } = validateField(curr, submission[key].value);

        if (invalidMessage) {
          acc[key] = invalidMessage;

          if (!focused) {
            const input = inputRefs.current[key];

            if (input && input.focus) {
              input.focus();
              focused = true;
            } else {
              fieldRefs.current[key].scrollIntoView({
                behavior: 'smooth',
                block: 'center',
              });
              focused = true;
            }
          }
        }

        return acc;
      }, {}) || {};

    updateErrors(currentErrors);

    return currentErrors;
  };

  const handleSubmit = () => {
    reportBi(formBuilderBackofficeFormSubmissionClick(getBiEventData()));

    const submissionValidationError = validateSubmission();

    if (Object.keys(submissionValidationError).length === 0) {
      setSubmissionState({
        submitting: true,
        submitted: false,
        errorCode: undefined,
      });
      onSubmit(submission)
        .then(() => {
          reportBi(
            formBuilderBackofficeFormSubmissionSuccess(getBiEventData())
          );
          setSubmissionState({
            submitting: false,
            submitted: true,
            errorCode: undefined,
          });
        })
        .catch((error) => {
          reportBi(
            formBuilderBackofficeFormSubmissionFailed({
              ...getBiEventData(),
              errorReason: error?.message,
            })
          );
          if (error && error.isFileUploadError) {
            setSubmissionState({
              submitting: false,
              submitted: false,
              errorCode: undefined,
            });
            updateErrors((prev) => ({
              ...prev,
              [error.fieldKey]: getFileValidationMessage(
                error.translationKey,
                error.args,
                t
              ),
            }));
          } else {
            const errorCode = error?.status || error?.message || 'unknown';
            setSubmissionState({
              submitting: false,
              submitted: false,
              errorCode,
            });
            throw new SubmitFormError(error);
          }
        });
    } else {
      reportBi(
        formBuilderBackofficeFormSubmissionFailed({
          ...getBiEventData(),
          errorReason: `invalid field(s): ${Object.keys(
            submissionValidationError
          ).join(', ')}`,
        })
      );
    }
  };

  const ErrorMessage = () => {
    let message = '';

    switch (submissionState.errorCode) {
      case 'Network Error':
        message = t('form.submission.error.network');
        break;
      default:
        message = t('form.submission.error.unknown');
    }

    return (
      <Box align="center" textAlign="center" marginTop="18px">
        <Text skin="error" dataHook="error-message">
          {message}
        </Text>
      </Box>
    );
  };

  if (submissionState.submitted) {
    return (
      <div className={containerClassName}>
        <div className={css.successMessage}>
          <div className={css.icon}>
            <SuccessIcon />
          </div>
          <Heading size="medium" dataHook="success-message">
            {form.formViewInfo?.successMessage}
          </Heading>
        </div>
      </div>
    );
  }

  return (
    <form
      data-hook="form"
      className={containerClassName}
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
      noValidate
    >
      <div className={css.header}>
        <Heading
          appearance="H2"
          className={css.title}
          dataHook="title"
          ellipsis={false}
        >
          {form.formViewInfo?.title}
        </Heading>
        <Text
          textAlign="center"
          dataHook="description"
          className={css.description}
          ellipsis={false}
        >
          {form.formViewInfo?.description}
        </Text>
      </div>
      <div className={css.fields}>
        {form.fields?.map((field) => (
          <div
            id={field.key}
            className={`
              ${
                field.renderInfo?.type
                  ? overridesClassPerFieldType[field.renderInfo.type] ||
                    css.field
                  : css.field
              }
            `}
            key={field.key}
            data-hook="field"
            ref={(e: HTMLDivElement) => {
              fieldRefs.current[field.key as string] = e;
            }}
          >
            {renderField(field)}
          </div>
        ))}
      </div>
      <div className={css.submitButton}>
        <Box maxWidth="255px">
          <Button
            skin="dark"
            size="large"
            type="submit"
            dataHook="submit-button"
            disabled={submissionState.submitting}
          >
            {submissionState.submitting ? (
              <Loader size="tiny" />
            ) : (
              <Text light ellipsis showTooltip={false}>
                {form.formViewInfo?.submitButtonInfo?.text}
              </Text>
            )}
          </Button>
        </Box>
      </div>
      {submissionState.errorCode ? <ErrorMessage /> : null}
    </form>
  );
};

export default withTranslation()(Form);
