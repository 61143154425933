import React, { useState } from 'react';
import { Checkbox, FormField } from '@wix/design-system';

export interface SubscribeFieldsProps {
  field: FormViewField;
  onChange(value: boolean): void;
}
export const SubscribeField = ({
  field: { renderInfo },
  onChange,
}: SubscribeFieldsProps) => {
  const [isChecked, setIsChecked] = useState(
    renderInfo?.displayProperties?.defaultValue || undefined
  );
  return (
    <FormField
      dataHook="subscribe-checkbox-form-field"
      label={renderInfo?.displayProperties?.checkboxSettings?.linkLabel}
      required={!!renderInfo?.validationProperties?.required}
      labelPlacement="right"
      stretchContent={false}
    >
      <Checkbox
        dataHook="checkbox-input"
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(!isChecked);
          onChange(!isChecked);
        }}
      />
    </FormField>
  );
};
