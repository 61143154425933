import biLogger from '@wix/web-bi-logger';
import { Logger, ReportProps } from '@wix/web-bi-logger/dist/src/types';

class FormsLogger {
  public logger: Logger;

  constructor(msid: string | undefined) {
    this.logger = biLogger
      .factory({ endpoint: 'forms-viewer-standalone' })
      .updateDefaults({ src: 5, referrer: document.referrer || '' })
      .withUoUContext({ msid })
      .logger();
  }
}

const formsLogger = new FormsLogger(window.__BI__.msid);

export const reportBi = (reportProps: ReportProps) =>
  formsLogger.logger.report(reportProps);
