import { HttpError } from '@wix/http-client';

export const ErrorName = {
  FetchFormError: 'FetchFormError',
  SubmitFormError: 'SubmitFormError',
  FileUploadError: 'FileUploadError',
};

export type FormError = FetchFormError;

export class CustomError extends Error {
  public isCustomError = true;
  public readonly originalException: any;

  constructor(name: string, message: string, error?: any) {
    super(message);

    this.name = name;
    this.originalException = error;
  }
}

export class FetchFormError extends CustomError {
  constructor(error: ServerError) {
    super(
      ErrorName.FetchFormError,
      error?.statusCode
        ? `Failed to fetch form with status ${error?.statusCode}`
        : 'Failed to fetch form',
      error,
    );
  }
}

export class SubmitFormError extends CustomError {
  constructor(error: HttpError) {
    super(
      ErrorName.SubmitFormError,
      error?.isWixHttpError && error?.response?.status
        ? `Submit form failed with status ${error?.response?.status}`
        : 'Submit form failed',
      error,
    );
  }
}

export class FileUploadError extends CustomError {
  public fieldKey: string;
  public args: string[];
  public translationKey: string;
  public isFileUploadError: boolean;

  constructor(error: any, fieldKey: string) {
    const data = error?.response?.data || error;
    let errorInfo = data?.error_info;

    if (!errorInfo) {
      // media-platform api sometimes might return a different object
      if (data?.error && typeof data.error === 'string') {
        try {
          const errorData = JSON.parse(data?.error);
          if (errorData.error_info) {
            errorInfo = errorData.error_info;
          }
        } catch (_err) {}
      }
    }

    super(ErrorName.FileUploadError, data?.error_description || '', error);

    this.isFileUploadError = true;
    this.fieldKey = fieldKey;
    this.translationKey = errorInfo?.key;
    this.args = errorInfo?.args;
  }
}
