import React from 'react';
import { Text, FormField, LabelPlacement } from '@wix/design-system';

interface FormFieldProps {
  labelId?: string;
  forId?: string;
  dataHook: string;
  label?: string;
  required?: boolean;
  children?: JSX.Element;
  charCount?: number;
  stretchContent?: boolean;
  labelPlacement?: LabelPlacement;
}

const Label = ({
  labelId,
  forId,
  dataHook,
  label,
  required,
  children,
  charCount,
  labelPlacement,
  stretchContent,
}: FormFieldProps) => {
  return (
    <FormField
      stretchContent={stretchContent}
      labelPlacement={labelPlacement}
      id={forId}
      labelId={labelId}
      dataHook={dataHook}
      label={
        <Text ellipsis={false} secondary>
          {label}
        </Text>
      }
      required={required}
      charCount={charCount}
    >
      {children}
    </FormField>
  );
};

export default Label;
