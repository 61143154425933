import React, { useState } from 'react';
import { RadioGroup } from '@wix/design-system';
import { WithErrorMessage, withErrorMessage } from '../../withErrorMessage';
import FormField from '../FormField/FormField';

export interface RadioGroupProps extends WithErrorMessage {
  field: FormViewField;
  onChange(value: string | number): void;
}

const RadioGroupComponent = ({
  errorDescribedBy,
  invalidMessage,
  field: { key, renderInfo },
  onChange,
}: RadioGroupProps) => {
  const [value, setValue] = useState<string | number>(
    renderInfo?.displayProperties?.defaultValue
  );

  const labelId = `${key}-label`;

  return (
    <div
      aria-labelledby={labelId}
      aria-describedby={invalidMessage ? errorDescribedBy : undefined}
    >
      <FormField
        dataHook="radio-group-form-field"
        label={renderInfo?.displayProperties?.label}
        required={!!renderInfo?.validationProperties?.required}
        labelId={labelId}
      >
        <RadioGroup
          vAlign="top"
          onChange={(e) => {
            if (e) {
              setValue(e);
              onChange(e);
            }
          }}
          selectionArea="always"
          dataHook="radio-group-input"
          value={value}
        >
          {renderInfo?.displayProperties?.radioGroupSettings?.options?.map(
            (option, idx) => (
              <RadioGroup.Radio value={option.value} key={idx}>
                {option.label}
              </RadioGroup.Radio>
            )
          )}
        </RadioGroup>
      </FormField>
    </div>
  );
};

export default withErrorMessage(RadioGroupComponent);
